import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './admihair/components/auth/auth.guard';
import { SelecionarEmpresaGuard } from './admihair/components/selecionar-empresa/selecionar-empresa.guard';
import { SelecionarFranquiaGuard } from './admihair/components/selecionar-franquia/selecionar-franquia.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        canActivate: [
            AuthGuard
        ],
        children: [
            {
                path: 'apps', data: { breadcrumb: '' }, loadChildren: () => import('./admihair/components/apps/apps.module').then(m => m.AppsModule),
                canActivate: [
                    SelecionarEmpresaGuard
                ]
            },
            {
                path: 'rfarias', data: { breadcrumb: '' }, loadChildren: () => import('./admihair/components/rfarias/rfarias.module').then(m => m.RfariasModule),
                canActivate: [
                    SelecionarFranquiaGuard
                ]
            }
        ]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./admihair/components/auth/auth.module').then(m => m.AuthModule) },
    {
        path: 'selecionar-empresa', data: { breadcrumb: 'Selecionar Empresa' }, loadChildren: () => import('./admihair/components/selecionar-empresa/selecionar-empresa.module').then(m => m.SelecionarEmpresaModule),
        canActivate: [
            AuthGuard
        ],
    },
    {
        path: 'selecionar-franquia', data: { breadcrumb: 'Selecionar Franquia' }, loadChildren: () => import('./admihair/components/selecionar-franquia/selecionar-franquia.module').then(m => m.SelecionarFranquiaModule),
        canActivate: [
            AuthGuard
        ],
    },
    {
        path: 'janela-empresa', data: { breadcrumb: '' }, loadChildren: () => import('./admihair/components/janelas-empresa/janelas-empresa.module').then(m => m.JanelasEmpresaModule),
        canActivate: [
            AuthGuard
        ],
    },
    {
        path: 'primeiro-acesso', data: { breadcrumb: 'Primeiro Acesso' }, loadChildren: () => import('./admihair/components/primeiro-acesso/primeiro-acesso.module').then(m => m.PrimeiroAcessoModule),
        canActivate: [
            AuthGuard
        ],
    },
    {
        path: 'confirmar-conta', data: { breadcrumb: 'Confirmar Conta' }, loadChildren: () => import('./admihair/components/confirmar-conta/confirmar-conta.module').then(m => m.ConfirmarContaModule),

    },

    { path: 'notfound', loadChildren: () => import('./admihair/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
